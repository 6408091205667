<template>
    <div>
        <v-snackbar class="white--text text-center" top v-model="snackbar" :timeout="3000" :color="notificationType">{{notificationText}}</v-snackbar>
        <img class="d-block" width="300" :src="require('@/assets/logo.png')" alt="Group Trip">
        <h4 class="subheader subheaderGrey--text mt-2 ml-5 text-bold text-uppercase">Forget your Password?</h4>
        <small class="caption grey--text pl-5">Enter your email and we’ll send you link to get back to your account</small>

        <v-form v-if="!showSuccessMsg" v-model="valid" ref="forgotPass" class="mt-5 mr-10">
            <div class="form-group">
                <label for="email" class="ml-5 paragraph--text">Email Address</label>
                <v-text-field height="60" id="email" outlined rounded type="email"
                v-model="credintials.email"
                :rules="[v => !!v || 'Email is required', v => /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(v) || 'E-mail must be valid']"
                >
                    <template v-slot:prepend-inner>
                        <div class="field-item">
                            <v-icon color="forms">mdi-email-outline</v-icon>
                        </div>
                    </template>
                </v-text-field>
            </div>

            <v-btn rounded :loading="loading" height="60" color="pink white--text" block large @click="forgotPass">submit</v-btn>

        </v-form>
        <v-card outlined v-else class="mt-5 pa-3">
          <h2 class="success--text text-center">Request sent successfully</h2>
          <p class="text-center">Please check your email to reset your password</p>
        </v-card>
    </div>
</template>

<script>
import auth from '@/web_services/auth'

export default {
  data () {
    return {
      snackbar: false,
      notificationType: '',
      notificationText: '',
      valid: true,
      credintials: {
        email: ''
      },
      showSuccessMsg: false,
      loading: false
    }
  },
  methods: {
    forgotPass () {
      this.$refs.forgotPass.validate()
      if (this.valid) {
        this.loading = true
        auth.forgotPass(this.credintials).then(res => {
          this.showSuccessMsg = true
        }).catch(err => {
          this.showNotification('error', err.response.data.message)
        }).finally(() => {
          this.loading = false
        })
      } else this.showNotification('warning', 'please enter your email')
    },
    showNotification (color, text) {
      this.snackbar = true
      this.notificationType = color
      this.notificationText = text
    }
  }
}
</script>

<style>
.v-text-field--outlined fieldset {
    border-color: #cecece !important;
}
.field-item {
    padding-right: 15px;
    border-right: 1px solid #cecece;
}
</style>
